export enum SFX {
  TROLLEY_TOOT = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/ES_Trolley+Horn+Beep+-+SFX+Producer.mp3',
  CHUGGA_CHUGGA = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyRumbleShort.mp3',
  SCREAM_1 = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/shortFemScream.mp3',
  COW_BELL = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/audio/heif.cowBell.mp3',
  MOOO = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/audio/heif.mooo.mp3',
  CANNON = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/audio/heif.cannon.mp3',
  OCEAN = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/audio/heif.oceanBGSFX.mp3',

}
