import React, { useState, useEffect } from "react";
import "./Ocean.css";
import { CHARACTER_IMAGES, VESSEL_IMAGES } from "../../assets/images/images";
import {
  knotsTraveledAtom,
  newCowInteractionAtom,
} from "../../state/gameState";
import { useAtom } from "jotai";
import KnotsTracker from "../components/KnotsTracker/KnotsTracker";
import FloatingBovine from "../components/FloatingBovine/FloatingBovine";
import Seamus from "../components/FloatingBovine/Seamus/Seamus";

type OceanProps = {
  handleClickSeamus: () => void;
};

const Ocean: React.FC<OceanProps> = ({ handleClickSeamus }) => {
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });

  const [knotsTraveled, setKnotsTraveled] = useAtom(knotsTraveledAtom);
  const [newCowInteraction, setNewCowInteraction] = useAtom(
    newCowInteractionAtom
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    setBackgroundPosition((prevPosition) => {
      let newX = prevPosition.x;
      let newY = prevPosition.y;

      switch (e.key) {
        case "ArrowUp":
          newY += 10;
          break;
        case "ArrowDown":
          newY -= 10;
          break;
        case "ArrowLeft":
          newX += 10;
          break;
        case "ArrowRight":
          newX -= 10;
          break;
        default:
          return prevPosition;
      }

      setKnotsTraveled((prevKnots) => prevKnots + 1);

      return { x: newX, y: newY };
    });
  };

  const handleTouchMove = (e: TouchEvent) => {
    const touch = e.touches[0];
    setBackgroundPosition((prevPosition) => {
      let newX = prevPosition.x + (window.innerWidth / 2 - touch.clientX) * 0.1;
      let newY =
        prevPosition.y + (window.innerHeight / 2 - touch.clientY) * 0.1;

      setKnotsTraveled((prevKnots) => prevKnots + 1);
      return { x: newX, y: newY };
    });
  };

  useEffect(() => {
    if (!newCowInteraction) {
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("touchmove", handleTouchMove);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [newCowInteraction]);

  useEffect(() => {
    if (knotsTraveled >= 500 && !newCowInteraction) {
      setNewCowInteraction(true);

      const timer = setTimeout(() => {
        setNewCowInteraction(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [knotsTraveled, newCowInteraction]);

  return (
    <div
      className="oceanContainer"
      style={{
        backgroundPosition: `${backgroundPosition.x}px ${backgroundPosition.y}px`,
      }}
    >
      <FloatingBovine />
      {newCowInteraction && <Seamus handleClickSeamus={handleClickSeamus} />}

      {!newCowInteraction && <KnotsTracker knots={knotsTraveled} />}
    </div>
  );
};

export default Ocean;
