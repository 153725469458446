import React, { useEffect, useState } from 'react'
import './Landing.css'
import { IMAGES } from '../../assets/images/images'

type LandingProps = {
  handleFirstInteraction: () => void
}

const Landing: React.FC<LandingProps> = ({ handleFirstInteraction }) => {
  const [fadeClass, setFadeClass] = useState('')
  const [driftClass, setDriftClass] = useState('')

  const startFade = () => {
    setFadeClass('fade')
    setTimeout(() => {
      handleFirstInteraction()
    }, 2000)
  }

  useEffect(() => {
    setDriftClass('drift')
  }, [])

  return (
    <div>
      <div className={`container ${fadeClass}`}></div>
      <div className={`centerContainer ${driftClass}`}>
        <button className="button" onClick={startFade}>
          <img className="image" src={IMAGES.COW_BUTTON} alt="Cow Button" />
        </button>
      </div>
    </div>
  )
}

export default Landing
