import React from "react";
import "./Seamus.css";
import {
  CHARACTER_IMAGES,
  VESSEL_IMAGES,
} from "../../../../assets/images/images";

interface FloatingBovineProps {
  handleClickSeamus: () => void;
}

const Seamus: React.FC<FloatingBovineProps> = ({ handleClickSeamus }) => {
  const startFade = () => {
    setTimeout(() => {
      console.log("mooo click seamus");
    }, 2000);
  };

  return (
    <div className={`seamusContainer`}>
      <button className="button" onClick={handleClickSeamus}>
        <img className="seamusImage" src={CHARACTER_IMAGES.SEAMUS} />
      </button>
      <img className="seamusRaftImage" src={VESSEL_IMAGES.DINGHY} alt="Raft" />
    </div>
  );
};

export default Seamus;
