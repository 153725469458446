import React from "react";
import "./KnotsTracker.css";
import { IMAGES } from "../../../assets/images/images";

interface KnotsTrackerProps {
  knots: number;
}

const KnotsTracker: React.FC<KnotsTrackerProps> = ({ knots }) => {
  return (
    <div className="knotsTrackerContainer">
      <img className="helmImage" src={IMAGES.HELM} alt="helm"></img>
      <div className="knotsTracker">Knots: {knots}</div>
    </div>
  );
};

export default KnotsTracker;
