export enum SCENES {
  LANDING = 'LANDING',
  INTRO = 'INTRO',
  TROLLEY_FLASH = 'TROLLEY_FLASH',
  NONE = 'NONE',
  LEAVING_HOME = 'LEAVING_HOME',
  SET_SAIL = 'SET_SAIL',
  OCEAN = 'OCEAN',
  OPEN_OCEAN = 'OPEN_OCEAN',
  SEAMUS = 'SEAMUS',
}
