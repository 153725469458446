import React, { useEffect, useRef } from 'react'
import './LeavingHome.css'
import { IMAGES } from '../../assets/images/images'

type LandingProps = {
  handleSetSail: () => void
}

const LeavingHome: React.FC<LandingProps> = ({ handleSetSail }) => {
  const fadeRef = useRef<HTMLImageElement>(null)
  const fadeRef2 = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (fadeRef.current) {
      fadeRef.current.style.transition = 'opacity 11s'
      fadeRef.current.style.opacity = '1'
    }

    if (fadeRef2.current) {
      fadeRef2.current.style.transition = 'opacity 11s'
      fadeRef2.current.style.opacity = '1'
    }
  }, [])

  return (
    <div className="leavingHomeContainer">
      <img
        ref={fadeRef}
        className="leavingHomeImage"
        src={IMAGES.LEAVING_HOME}
        alt="Leaving Home"
      />
      <button ref={fadeRef2} className="leavingHomeButton" onClick={handleSetSail}>
        LEAVE!
      </button>
    </div>
  )
}

export default LeavingHome
