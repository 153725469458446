import { useAtom } from "jotai";
import { useEffect } from "react";
import { SCENES } from "./assets/constants/scenes";
import { Intro } from "./screens/Intro/Intro";
import Landing from "./screens/Landing/Landing";
import LeavingHome from "./screens/LeavingHome.tsx/LeavingHome";
import TrolleyFlash from "./screens/TrolleyFlash/TrolleyFlash";
import AppExecutor from "./services/Executor/AppExecutor";
import { newCowInteractionAtom, sceneAtom } from "./state/gameState";
import Ocean from "./screens/Ocean/Ocean";

const App: React.FC = () => {
  const [scene, setScene] = useAtom(sceneAtom);
  const [newCowInteraction, setNewCowInteraction] = useAtom(
    newCowInteractionAtom
  );
  const executor = new AppExecutor();

  let hasInteractedWithPage = false;
  let hasSetSail = false;

  const handleFirstInteraction = async () => {
    hasInteractedWithPage = true;

    setScene(SCENES.LANDING);

    await executor.exec(SCENES.LANDING);
    await executor.exec(SCENES.INTRO);

    setTimeout(() => {
      setScene(SCENES.INTRO);
    }, 6000);

    setTimeout(() => {
      setScene(SCENES.TROLLEY_FLASH);
    }, 6100);

    await executor.exec(SCENES.TROLLEY_FLASH);

    setTimeout(async () => {
      setScene(SCENES.LEAVING_HOME);
      await executor.exec(SCENES.LEAVING_HOME);
    }, 8000);
  };

  const handleSetSail = async () => {
    hasSetSail = true;

    if (!newCowInteraction) {
      setScene(SCENES.SET_SAIL);
      await executor.exec(SCENES.SET_SAIL);
    }
    setTimeout(async () => {
      await executor.exec(SCENES.OPEN_OCEAN);
    }, 8000);
  };

  const handleClickSeamus = async () => {
    await executor.exec(SCENES.SEAMUS);
    setTimeout(async () => {
      setNewCowInteraction(false);
    }, 5000);
  };

  return (
    <div className="container">
      {scene === SCENES.LANDING && (
        <Landing handleFirstInteraction={handleFirstInteraction} />
      )}
      {scene === SCENES.INTRO && <Intro />}
      {scene === SCENES.TROLLEY_FLASH && <TrolleyFlash />}
      {scene === SCENES.LEAVING_HOME && (
        <LeavingHome handleSetSail={handleSetSail} />
      )}
      {scene === SCENES.SET_SAIL && (
        <Ocean handleClickSeamus={handleClickSeamus} />
      )}
    </div>
  );
};

export default App;
