import React, { useEffect, useState } from 'react'
import './TrolleyFlash.css'
import { IMAGES } from '../../assets/images/images'

const TrolleyFlash: React.FC = () => {
  const [flashState, setFlashState] = useState('')

  useEffect(() => {
    setFlashState('expand')
    const timer1 = setTimeout(() => {
      setFlashState('shrink')
    }, 2000) // Increased duration before starting to shrink

    const timer2 = setTimeout(() => {
      setFlashState('')
    }, 5000) // Increased duration to complete the shrinking phase

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [])

  return (
    <div className="flashContainer">
      <div className={`flashImageContainer ${flashState}`}>
        <img className="flashImage" src={IMAGES.TROLLEY} alt="Trolley" />
      </div>
    </div>
  )
}

export default TrolleyFlash
