export enum STAMPS_COPY {
  INTRO_1 = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/heif.stamps.intro1.mp3',
  INTRO_2 = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/heif.stamps.intro2.mp3',
  INTRO_3 = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/heif.stamps.intro3.mp3',
}

export enum GOD_COPY {
  INTRO_1 = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/heif.god.intro1.mp3',
}

export enum AL_COPY {
    INTRO_LAMENT = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/audio/heif.AlLament.mp3'
}

export enum SEAMUS_COPY {
  INTRO = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.SeamusIntro.mp3'
}