import { getDefaultStore, useAtom, useSetAtom } from "jotai";

import { IAppExecutor } from "./IAppExecutor";
import {
  AL_COPY,
  GOD_COPY,
  SEAMUS_COPY,
  STAMPS_COPY,
} from "../../assets/audio/copy";
import { SFX } from "../../assets/audio/sfx";
import { SCENES } from "../../assets/constants/scenes";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import { IAudioPlayer } from "../AudioPlayer/IAudioPlayer";
import { sceneAtom } from "../../state/gameState";

export default class AppExecutor implements IAppExecutor {
  protected audioPlayer: IAudioPlayer = new AudioPlayer();

  protected setScene = useSetAtom(sceneAtom);

  public exec = async (scene?: string) => {
    console.log("🐄🐄🐄🐄🐄");
    console.log("vote for ittttt cmmmmmon");
    console.log("🐄🐄🐄🐄🐄");
    console.log("cmmmmonnnnnn i'll venmo you cmmmmonnnnnn");
    console.log("🐄🐄🐄🐄🐄");

    if (!scene) {
      this.init();
    }
    if (scene === SCENES.LANDING) {
      await this.landing();
    } else if (scene === SCENES.INTRO) {
      await this.intro();
    } else if (scene === SCENES.TROLLEY_FLASH) {
      await this.trolleyFlash();
    } else if (scene === SCENES.LEAVING_HOME) {
      await this.leavingHome();
    } else if (scene === SCENES.SET_SAIL) {
      await this.setSail();
    } else if (scene === SCENES.OPEN_OCEAN) {
      await this.openOcean();
    } else if (scene === SCENES.SEAMUS) {
      await this.seamus();
    }
  };

  protected init() {
    this.audioPlayer.init();
  }

  public async landing() {
    this.audioPlayer.init();
    await this.audioPlayer.playSound(SFX.TROLLEY_TOOT);
  }
  public async intro() {
    await this.audioPlayer.playSound(GOD_COPY.INTRO_1);
  }

  public async trolleyFlash() {
    await this.audioPlayer.playSound(SFX.SCREAM_1);
  }

  public async leavingHome() {
    await this.audioPlayer.playSound(SFX.COW_BELL, false);
    await this.audioPlayer.playSound(SFX.MOOO);
  }

  public async setSail() {
    this.audioPlayer.init();
    await this.audioPlayer.playSound(SFX.CANNON);
    await this.audioPlayer.playSound(STAMPS_COPY.INTRO_1);
    await this.audioPlayer.playSound(SFX.CANNON);
    await this.audioPlayer.playSound(STAMPS_COPY.INTRO_2);
    await this.audioPlayer.playSound(SFX.CANNON);
    await this.audioPlayer.playSound(STAMPS_COPY.INTRO_3);
    await this.audioPlayer.playSound(AL_COPY.INTRO_LAMENT);
  }

  public async openOcean() {
    await this.audioPlayer.playSound(SFX.OCEAN, false);
  }

  public async seamus() {
    this.audioPlayer.init();
    await this.audioPlayer.playSound(SFX.MOOO, false);
    await this.audioPlayer.playSound(SFX.MOOO, false);
    await this.audioPlayer.playSound(SFX.MOOO, false);
    await this.audioPlayer.playSound(SEAMUS_COPY.INTRO);
  }
}
