export enum IMAGES {
  COW_BUTTON = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.cowButtonNoBG.png',
  TROLLEY = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/trolleyTitle.png',
  LEAVING_HOME = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.leavingHome.png',
  HELM = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.HelmIcon.png'
}

export enum CHARACTER_IMAGES {
  AL = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.AlIcon.png',
  SEAMUS = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.Seamus.png'
}

export enum VESSEL_IMAGES {
  PIRATE_SHIP = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.pirateShip.png',
  SAIL_BOAT = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.sailBoat.png',
  SPORT_BOAT = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.sportBoat.png',
  DINGHY = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.dinghy.png',
  RAFT = 'https://custom-skills-public.s3.amazonaws.com/trollilopolis/heifferopolis/images/heif.introRaft.png'
}