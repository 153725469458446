import React from "react";
import "./FloatingBovine.css";
import { CHARACTER_IMAGES, VESSEL_IMAGES } from "../../../assets/images/images";

interface FloatingBovineProps {
  className?: string;
  style?: React.CSSProperties;
  altText?: string;
}

const FloatingBovine: React.FC<FloatingBovineProps> = ({
  className,
  style,
  altText = "Cow on Raft",
}) => {
  return (
    <div className={`floatingBovineContainer ${className}`} style={style}>
      <img className="cowImage" src={CHARACTER_IMAGES.AL} alt={altText} />
      <img className="raftImage" src={VESSEL_IMAGES.RAFT} alt="Raft" />
    </div>
  );
};

export default FloatingBovine;
