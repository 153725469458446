import { Howl } from 'howler'
import { IAudioPlayer } from './IAudioPlayer'

/**
 * Class for playing audio tracks in sequence or simultaneously.
 */
export class AudioPlayer implements IAudioPlayer {
  protected soundQueue: string[] = []
  protected isProcessingQueue = false
  public isInitialized = false

  public init() {
    this.isInitialized = true
  }

  /**
   * Plays audio tracks in a sequence or simultaneously
   * @param src - URI of the audio file to play.
   * @param sync - If true, plays audio tracks in a sequence. If false, plays multiple audio tracks simultaneously by repeated invocation of playSound method where sync param is false
   */
  public async playSound(src: string, sync = true): Promise<void> {
    if (!this.isInitialized) {
      console.log('AudioPlayer not initialized')
      return
    }
    if (!sync) {
      await this.playSoundAsync(src)
    } else {
      this.soundQueue.push(src)
      this.processQueue()
    }
  }

  /**
   * Plays multiple audio tracks simultaneously by repeated invocation of playSound method where sync param is false
   * @param src Path to the audio file to play.
   * @returns A Promise-wrapped AVPlaybackStatus
   */
  protected async playSoundAsync(src: string) {
    const sound = new Howl({
      src: [src],
      html5: true,
      volume: 0.5, 
    })
    return new Promise<void>((resolve, reject) => {
      sound.once('load', () => {
        sound.play()
        resolve()
      })
      sound.once('loaderror', (id, error) => {
        reject(new Error(AudioPlayerErrorMessages.playSoundAsync(error)))
      })
    })
  }

  /**
   * Plays audio tracks in a sequence
   * @param src URI of the audio file to play.
   */
  protected async playSoundSync(src: string): Promise<void> {
    const sound = new Howl({
      src: [src],
      html5: true,
      volume: 1.0, // Ensure volume is set
    })
    return new Promise<void>((resolve, reject) => {
      sound.once('load', () => {
        sound.play()
      })
      sound.once('end', () => {
        resolve()
      })
      sound.once('loaderror', (id, error) => {
        reject(new Error(AudioPlayerErrorMessages.playSoundSync(error)))
      })
    })
  }

  /**
   * Processes the queue of audio URIs using playSoundSync to play them in sequence.
   */
  protected async processQueue(): Promise<void> {
    if (this.isProcessingQueue || this.soundQueue.length === 0) {
      return
    }

    this.isProcessingQueue = true
    while (this.soundQueue.length > 0) {
      const src = this.soundQueue.shift() as string
      await this.playSoundSync(src)
    }
    this.isProcessingQueue = false
  }
}

export const AudioPlayerErrorMessages = {
  playSoundSync: (error: unknown) =>
    `Error in AudioPlayerService method playSoundSync: ${error}`,
  playSoundAsync: (error: unknown) =>
    `Error in AudioPlayerService method playSoundAsync: ${error}`,
} as const
